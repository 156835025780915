import { FC, useCallback, useMemo } from 'react';
import { CheckboxFilterValue } from '../../../models/TableViewFilters';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { useTranslation } from 'react-i18next';
import RadioButton from '../../shared/form-control/RadioButton';
import { FilterProps } from './FilterProps';

const CheckboxFilter: FC<FilterProps> = (props) => {
  const { columnConfig, selectedTemplateId } = props;
  const { filters, setFilters } = useTableViewFilters();
  const { t } = useTranslation('table-view');

  const filterValue = useMemo(() => {
    return filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as CheckboxFilterValue | undefined;
  }, [columnConfig.value, filters, selectedTemplateId]);

  const onChange = useCallback(
    (value: boolean) => {
      setFilters(columnConfig, selectedTemplateId, value);
    },
    [columnConfig, selectedTemplateId, setFilters],
  );

  return (
    <div>
      <RadioButton label={t('filters.checkbox.unchecked')} value={filterValue === false} onChange={() => onChange(false)} />
      <RadioButton label={t('filters.checkbox.checked')} value={filterValue === true} onChange={() => onChange(true)} />
    </div>
  );
};

export default CheckboxFilter;
